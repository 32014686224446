import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'

import ProtectedRoute from './pages/users/partials/auth/protected.route'

const LandingIndex = loadable(() => import('./pages/landing/index_ts')),
      UsersIndex = loadable(() => import('./pages/users/index')),
      DashboardIndex = loadable(() => import('./pages/dashboard/index_ts')),
      ItemsNew = loadable(() => import('./pages/items/new')),
      ItemsEdit = loadable(() => import('./pages/items/edit')),
      InventoryIndex = loadable(() => import('./pages/inventory/index')),
      InventoryDetail = loadable(() => import('./pages/inventory/detail')),
      InventoryNew = loadable(() => import('./pages/inventory/new')),
      // InventoryEdit = loadable(() => import('./pages/inventory/edit')),
      InventoryConfirm = loadable(() => import('./pages/inventory/confirm')),
      InventoryPutAway = loadable(() => import('./pages/inventory/putaway')),
      SalesIndex = loadable(() => import('./pages/sales/index')),
      OrdersNew = loadable(() => import('./pages/orders/new')),
      OrdersEdit = loadable(() => import('./pages/orders/edit')),
      OrdersIndex = loadable(() => import('./pages/orders/index')),
      OrdersDetail = loadable(() => import('./pages/orders/detail')),
      InvoicesIndex = loadable(() => import('./pages/invoices/index_ts')),
      InvoicesDetail = loadable(() => import('./pages/invoices/detail_ts')),
      InvoicesNew = loadable(() => import('./pages/invoices/new_ts')),
      SettingIndex = loadable(() => import('./pages/settings/index_ts')),
      SettingDetail = loadable(() => import('./pages/settings/detail')),
      PrintIndex = loadable(() => import('./pages/prints/index')),
      ActivityIndex = loadable(() => import('./pages/activity/index_ts')),
      ReportingIndex = loadable(() => import('./pages/reporting/index_ts')),
      ReportingDetail = loadable(() => import('./pages/reporting/detail_ts')),
      ItemsIndex = loadable(() => import('./pages/items/index')),
      ItemsDetail = loadable(() => import('./pages/items/detail')),
      AnalyticsIndex = loadable(() => import('./pages/analytics/index')),
      ChatsIndex = loadable(() => import('./pages/chats/index')),
      StaticIndex = loadable(() => import('./pages/static/index')),
      PromotionsIndex = loadable(() => import('./pages/promotions/index')),
      PromotionsNew = loadable(() => import('./pages/promotions/new')),
      PromotionsDetail = loadable(() => import('./pages/promotions/detail')),
      SalesReturnIndex = loadable(() => import('./pages/returns/index')),
      SalesReturnDetail = loadable(() => import('./pages/returns/detail')),
      SalesReturnNew = loadable(() => import('./pages/returns/new'))
      
const Root = () => {
  return (
    <BrowserRouter basename={'/'} >
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={LandingIndex}/>

        <ProtectedRoute as='dashboard' path={`${process.env.PUBLIC_URL}/dashboard/home`} component={DashboardIndex}/>

        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/master/edit`} sections='master' component={ItemsEdit}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/master/new`} sections='master' component={ItemsNew}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/bundle/edit`} sections='bundle' component={ItemsEdit}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/bundle/new`} sections='bundle' component={ItemsNew}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/master/:id`} sections='master' component={ItemsDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/master`} sections='master' component={ItemsIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/listing/edit`} sections='listing' component={ItemsEdit}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/listing/new`} sections='listing' component={ItemsNew}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/listing/:id`} sections='listing' component={ItemsIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/pending`} sections='pending' component={ItemsIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/cross`} sections='cross' component={ItemsIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/priceadjustment/new`} sections='priceadjustment' component={InventoryNew}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/priceadjustment/:id`} sections='priceadjustment' component={InventoryDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/items/priceadjustment`} sections='priceadjustment' component={InventoryIndex}/>

        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/quantity/:id`} sections='quantity' component={InventoryDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/quantity`} sections='quantity' component={InventoryIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockadjustment/new`} sections='adjustment' component={InventoryNew}/>
        {/* <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockadjustment/edit`} sections='adjustment' component={InventoryEdit}/> */}
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockadjustment/confirm/:id`} sections='adjustment' component={InventoryConfirm}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockadjustment/:id`} sections='adjustment' component={InventoryDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockadjustment`} sections='adjustment' component={InventoryIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockinbound/new`} sections='inbound' component={InventoryNew}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockinbound/confirm/:id`} sections='inbound' component={InventoryConfirm}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockinbound/putaway/:id`} sections='inbound' component={InventoryPutAway}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockinbound/:id`} sections='inbound' component={InventoryDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockinbound`} sections='inbound' component={InventoryIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockoutbound/new`} sections='outbound' component={InventoryNew}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/putaway/:id`} sections='putaway' component={InventoryDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/putaway`} sections='putaway' component={InventoryIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockoutbound/confirm/:id`} sections='outbound' component={InventoryConfirm}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockoutbound/:id`} sections='outbound' component={InventoryDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockoutbound`} sections='outbound' component={InventoryIndex}/>
        {/* <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockpromotions`} sections='promotions' component={InventoryIndex}/> */}
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stocktransfer/new`} sections='transfer' component={InventoryNew}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stocktransfer/:id`} sections='transfer' component={InventoryDetail}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stocktransfer`} sections='transfer' component={InventoryIndex}/>
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/lowstockalert`} sections='lowstockalert' component={InventoryIndex}/>
        {/* <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/salesreturn`} sections='salesreturn' component={InventoryIndex}/> */}
        <ProtectedRoute as='items' path={`${process.env.PUBLIC_URL}/dashboard/inventory/stockhistory`} sections='stockhistory' component={InventoryIndex}/>

        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/sales/new`} component={SalesIndex}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/salesorders/:id`} component={OrdersDetail}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/salesorders`} sections='salesorders' component={OrdersIndex}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/picklists/new`} sections='picklists' component={OrdersNew}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/picklists/edit`} sections='picklists' component={OrdersEdit}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/picklists/:id`} sections='picklists' component={OrdersDetail}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/picklists`} sections='picklists' component={OrdersIndex}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/packages/new`} sections='packages' component={OrdersNew}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/packages/:id`} sections='packages' component={OrdersDetail}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/packages`} sections='packages' component={OrdersIndex}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/shipments/:id`} sections='shipments' component={OrdersDetail}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/orders/shipments`} sections='shipments' component={OrdersIndex}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/invoices/salesinvoices/:id`} sections='salesinvoices' component={InvoicesDetail}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/invoices/salesinvoices`} sections='salesinvoices' component={InvoicesIndex}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/invoices/paymentreceived/new`} sections='paymentreceived' component={InvoicesNew}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/invoices/paymentreceived/:id`} sections='paymentreceived' component={InvoicesDetail}/>
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/invoices/paymentreceived`} sections='paymentreceived' component={InvoicesIndex}/>

        <ProtectedRoute as='analytics' path={`${process.env.PUBLIC_URL}/dashboard/analytics`} component={AnalyticsIndex}/>
        
        <ProtectedRoute as='promotions' path={`${process.env.PUBLIC_URL}/dashboard/promotions/new`} sections='promotions' component={PromotionsNew}/>
        {/* <ProtectedRoute as='promotions' path={`${process.env.PUBLIC_URL}/dashboard/promotions/boostproducts`} sections='boostproducts' component={PromotionsIndex}/> */}
        <ProtectedRoute as='promotions' path={`${process.env.PUBLIC_URL}/dashboard/promotions/:id`} sections='promotions' component={PromotionsDetail}/>
        <ProtectedRoute as='promotions' path={`${process.env.PUBLIC_URL}/dashboard/promotions`} sections='promotions' component={PromotionsIndex}/>
        
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/:slug/:dashboardId`} component={ReportingDetail}/> 

        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/financial-transactions`} sections='transactions' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/profit-loss`} sections='profit_loss' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/gross-profit-by-store`} sections='gross_profit_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/gross-profit-by-product`} sections='gross_profit_product' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/product-sales-by-store`} sections='product_sales_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/service-fee-by-store`} sections='service_fee_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/voucher-seller-by-store`} sections='voucher_seller_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/voucher-code`} sections='voucher_code' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/platform-rebate-by-store`} sections='platform_rebate_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/shipping-difference-by-store`} sections='shipping_difference_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/fulfillment-fee-by-store`} sections='fulfillment_fee_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/other-income`} sections='other_income' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/other-expense`} sections='other_expense' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/vat-report`} sections='vat_report' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/product-transactions`} sections='product_transactions' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/sales-by-bundle`} sections='sales_bundle' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/sales-by-product`} sections='sales_product' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/sales-by-warehouse`} sections='sales_warehouse' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/sales-by-channel`} sections='sales_channel' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/sales-by-store`} sections='sales_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/sales-by-category`} sections='sales_category' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/sales-by-brand`} sections='sales_brand' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/unpaid-invoices-by-store`} sections='unpaid_invoices_store' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/stock-transaction`} sections='stock_transaction' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/price-history-report`} sections='price_history_report' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/inventory-report`} sections='inventory_report' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/inventory-valuation-report`} sections='inventory_valuation' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/warehouse-inventory-report`} sections='warehouse_inventory_report' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting/warehouse-valuation-report`} sections='warehouse_valuation' component={ReportingIndex}/>
        <ProtectedRoute as='reporting' path={`${process.env.PUBLIC_URL}/dashboard/reporting`} sections='reporting' component={ReportingIndex}/>
        
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/salesreturns/new`} component={SalesReturnNew} />
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/salesreturns/:id`} component={SalesReturnDetail} />
        <ProtectedRoute as='orders' path={`${process.env.PUBLIC_URL}/dashboard/salesreturns`} component={SalesReturnIndex} />

        <ProtectedRoute as='settings' path={`${process.env.PUBLIC_URL}/dashboard/settings/warehouses/:id`} sections='warehouses' component={SettingDetail}/>
        <ProtectedRoute as='settings' path={`${process.env.PUBLIC_URL}/dashboard/settings/warehouses`} sections='warehouses' component={SettingIndex}/>
        <ProtectedRoute as='settings' path={`${process.env.PUBLIC_URL}/dashboard/settings/taxes`} sections='taxes' component={SettingIndex}/>
        <ProtectedRoute as='settings' path={`${process.env.PUBLIC_URL}/dashboard/settings/invoices`} sections='invoices' component={SettingIndex}/>
        <ProtectedRoute as='settings' path={`${process.env.PUBLIC_URL}/dashboard/settings/inventory`} sections='inventory' component={SettingIndex}/>
        <ProtectedRoute as='settings' path={`${process.env.PUBLIC_URL}/dashboard/settings/print`} sections='print' component={SettingIndex}/>
        <ProtectedRoute as='settings' path={`${process.env.PUBLIC_URL}/dashboard/settings/general`} sections='general' component={SettingIndex}/>

        <ProtectedRoute as='activity' path={`${process.env.PUBLIC_URL}/dashboard/activity`} component={ActivityIndex}/>
        <ProtectedRoute as='prints' sections='shipping_labels' path={`${process.env.PUBLIC_URL}/dashboard/prints/shipping_labels/:id`} component={PrintIndex}/>
        <ProtectedRoute as='prints' sections='invoices' path={`${process.env.PUBLIC_URL}/dashboard/prints/invoices/:ids`} component={PrintIndex}/>
        <ProtectedRoute as='prints' sections='picklists' path={`${process.env.PUBLIC_URL}/dashboard/prints/picklists/:id`} component={PrintIndex}/>
        <ProtectedRoute as='prints' sections='packages' path={`${process.env.PUBLIC_URL}/dashboard/prints/packages/:ids`} component={PrintIndex}/>
        <ProtectedRoute as='prints' sections='receipt' path={`${process.env.PUBLIC_URL}/dashboard/prints/receipt/:id`} component={PrintIndex}/>

        <ProtectedRoute as='chats' path={`${process.env.PUBLIC_URL}/dashboard/chats`} component={ChatsIndex}/> 

        <Route path={`${process.env.PUBLIC_URL}/dashboard/users/forgot`} component={UsersIndex}/>
        <Route path={`${process.env.PUBLIC_URL}/dashboard/users/login`} component={UsersIndex}/>

        <Route path={`${process.env.PUBLIC_URL}/static/prints`} sections='prints' component={StaticIndex}/>

        <ProtectedRoute as='dashboard' path={`${process.env.PUBLIC_URL}/dashboard`} component={DashboardIndex}/>
      </Switch>
    </BrowserRouter>
  ) 
}

ReactDOM.render(<Root/>, document.getElementById('root'))